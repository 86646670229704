import React from 'react';
import './Header.css';
import i18n from 'i18next';
import { ColorModeProvider, Typography } from '@airbus/components-react';
import { AuthService } from 'react-oauth2-pkce';
import { Header as AirbusHeader, Select } from '@airbus/components-react';

import '../../i18n/config';
import { displayUserName } from '../../utils/ApplicationUtils';
import HeaderTab from './HeaderTab';
import TokenMenu from '../pop-in/TokenMenu';
import { computePermissions } from '../../utils/permission';

type Props = {
    authService: AuthService;
};

const Header = ({ authService }: Props) => {
    const appName = authService.isAuthenticated() ? 'Airspace Link' : 'XXX';
    const login = async () => authService.authorize();

    function handleAccountChange(option: string) {
        switch (option) {
            case 'Login':
                return login();
        }
    }

    const permissions = computePermissions();

    return (
        <ColorModeProvider mode="dark">
            <AirbusHeader className="header" appName={appName}>
                <HeaderTab
                    showAdmin={permissions.admin}
                    showValidationCenter={permissions.validationCenter}
                    showDeployment={permissions.deployment}
                    showAircraft={permissions.aircraft}
                />
                <TokenMenu
                    artifactoryToken={''} //userData.company?.authtoken}
                />
                {authService.isAuthenticated() ? (
                    <Typography variant="small">{displayUserName(authService.getUser())}</Typography>
                ) : (
                    <Select
                        placeholder="My account"
                        className="header-select"
                        onChange={(event) => {
                            if (event?.target?.value) handleAccountChange(event?.target?.value);
                        }}>
                        <option>{i18n.t('header.option1')}</option>
                    </Select>
                )}
            </AirbusHeader>
        </ColorModeProvider>
    );
};

export default Header;
