import React, { useEffect, useState } from 'react';
import { AuthProvider, AuthService } from 'react-oauth2-pkce';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import config from './config/config.json';
import Login from './components/auth/Login';
import AuthRoute from './components/auth/AuthRoute';
import Footer from './components/footer/Footer';
import GettingStarted from './components/getting-started/GettingStarted';
import Header from './components/header/Header';
import Home from './components/home/Home';
import log from './logger';
import { CurrentUser } from './models/CurrentUserModel';
import { PermissionPage } from './models/PermissionModel';
import MyApps from './components/my-apps/MyApps';
import SessionDialog from './components/pop-in/SessionDialog';
import Swagger from './components/swagger/Swagger';
import {
    selectToken,
    selectTokenExpiryDate,
    setToken,
    setCookie,
    setTokenExpiryDate,
    selectCookie
} from './redux/app/features/credentialsSlice';
import { selectCurrentUser, setCurrentUser } from './redux/app/features/userSlice';
import { isTokenExpired, redirectUri } from './utils/ApplicationUtils';
import { Permissions, computePermissions, initUserPagePermissions } from './utils/permission';
import * as Utils from './utils/ErrorUtils';
import Admin from './components/admin/Admin';
import { getCurrentUser } from './services/api/CurrentUserApi';
import MessageBanner from './shared/components/message-banner/MessageBanner';
import Deployment from './components/deployment/Deployment';
import ValidationCenterListing from './components/validation-center/Listing';
import ValidationCenterDetails from './components/validation-center/ValidationAppDetails';
import { AppContext, defaultMessageBanner } from './AppContext';
import { IError } from './models/AppModels';
import Aircraft from './components/aircraft-view/Aircraft';

import './App.css';
import AircraftConfiguration from './components/aircraft-view/aircraft-configuration/AircraftConfiguration';

const authService = new AuthService({
    clientId: config.clientid,
    provider: config.provider,
    location: window.location,
    redirectUri,
    scopes: config.scopes
});
const App = () => {
    const [permissions, setPermissions] = useState<Permissions>({
        user: false,
        adminRole: false,
        adminCompany: false,
        adminAircraft: false,
        admin: false,
        validationCenter: false,
        deployment: false,
        aircraft: false
    });
    const [needCurrentUser, setNeedCurrentUser] = useState<boolean>(false);
    const [messageBanner, setMessageBanner] = useState<IError>(defaultMessageBanner);

    const token = useSelector(selectToken);
    const tokenExpiryDate = useSelector(selectTokenExpiryDate);
    const cookie = useSelector(selectCookie);

    const currentUser: CurrentUser | undefined = useSelector(selectCurrentUser);

    const dispatch = useDispatch();

    if (!token && authService.getAuthTokens()?.id_token) {
        dispatch(setToken(authService.getAuthTokens().id_token));
    }

    if (!tokenExpiryDate && authService.getAuthTokens()?.expires_at) {
        dispatch(setTokenExpiryDate(authService.getAuthTokens().expires_at || 0));
    }

    if (
        !cookie &&
        authService.getAuthTokens()?.id_token &&
        authService.getAuthTokens()?.expires_at &&
        token !== undefined &&
        tokenExpiryDate !== undefined
    ) {
        dispatch(
            setCookie({
                token,
                expiryDate: new Date(tokenExpiryDate)
            })
        );
    }

    // Relog user if token is expired
    if (authService.isAuthenticated() && isTokenExpired(authService.getAuthTokens()?.expires_at ?? 0)) {
        authService.logout();
        authService.authorize();
    }

    const loginTimeOut = 5 * 1000; // 5 seconds
    function relog(): void {
        // if login is taking too long time and stuck in pending,
        // logout immediately for enabling a fresh login
        // it can happen when the token data is corrupt
        setTimeout(() => {
            if (authService.isPending()) {
                console.warn('Login Timeout, logging out. Try again!');
                alert('Login attempt timed out. Redirecting to LogIn page');
                window.localStorage.clear();
                authService.logout();
            }
        }, loginTimeOut);
    }

    relog();

    useEffect(() => {
        if (needCurrentUser) {
            getCurrentUser(token)
                .then((response: CurrentUser & Utils.Error200) => {
                    log.debug('[ARTIFACTORY_TOKEN-API] - Success');
                    let permissions: PermissionPage[] | undefined;

                    if (response.roles) {
                        permissions = response.roles.flatMap((role) =>
                            role.permissions.flatMap((permission) => permission.permission_page).filter((permission) => permission)
                        );
                    }
                    if (response.error) {
                        setMessageBanner({
                            isBanner: true,
                            message: `${response.error.message}`,
                            type: 'error'
                        });
                    }

                    initUserPagePermissions(permissions ?? []);

                    setPermissions(computePermissions());
                    dispatch(setCurrentUser(response));
                })
                .catch((error: any) => {
                    console.error("there's an issue in logging you in, due to: ", error);
                    setMessageBanner({
                        isBanner: true,
                        message: `there's an issue in logging you in, due to: ${Utils.displayErrorMessage(error)}`,
                        type: 'error'
                    });
                });
        }
    }, [needCurrentUser, token]);

    const isAuthWithLibrary = authService.isAuthenticated() && !isTokenExpired(authService.getAuthTokens()?.expires_at ?? 0);
    const componentIsReloading = isAuthWithLibrary && token === undefined && tokenExpiryDate === undefined && cookie === undefined;
    if (isAuthWithLibrary && !componentIsReloading && !needCurrentUser) {
        setNeedCurrentUser(true);
    }

    const isAuthButNoUser = token !== undefined && tokenExpiryDate !== undefined && cookie !== undefined && currentUser === undefined;

    const isFullyAuth = token !== undefined && tokenExpiryDate !== undefined && cookie !== undefined && currentUser !== undefined;

    const queryClient = new QueryClient();

    const routes = (
        <Switch>
            <AuthRoute
                exact
                path="/"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <Home />
            </AuthRoute>
            <AuthRoute
                exact
                path="/swagger"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <Swagger />
            </AuthRoute>
            <AuthRoute
                exact
                path="/getting-started"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <GettingStarted />
            </AuthRoute>
            <AuthRoute
                exact
                path="/applications"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <MyApps />
            </AuthRoute>
            <AuthRoute
                exact
                path="/validation-center"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <ValidationCenterListing />
            </AuthRoute>
            <AuthRoute
                exact
                path="/validation-center/:id"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <ValidationCenterDetails />
            </AuthRoute>
            <AuthRoute
                exact
                path="/deployment"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <Deployment />
            </AuthRoute>
            <AuthRoute
                exact
                path="/deployment/history"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <Deployment />
            </AuthRoute>
            <AuthRoute
                exact
                path="/deployment/schedule"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <Deployment />
            </AuthRoute>
            <AuthRoute
                exact
                path="/aircraft"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <Aircraft />
            </AuthRoute>
            <AuthRoute
                exact
                path="/aircraft/aircraft-configuration/"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <AircraftConfiguration />
            </AuthRoute>
            <AuthRoute
                exact
                path="/aircraft/aircraft-configuration/overview/:id"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <AircraftConfiguration />
            </AuthRoute>
            <AuthRoute
                exact
                path="/aircraft/aircraft-configuration/deployment-history/:id"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <AircraftConfiguration />
            </AuthRoute>
            <AuthRoute
                exact
                path="/aircraft/aircraft-view"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <Aircraft />
            </AuthRoute>
            <AuthRoute
                exact
                path="/aircraft/fleet-view"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <Aircraft />
            </AuthRoute>
            <AuthRoute
                exact
                path="/admin"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <Admin />
            </AuthRoute>
            <AuthRoute
                exact
                path="/admin/company"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <Admin />
            </AuthRoute>
            <AuthRoute
                exact
                path="/admin/user"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <Admin />
            </AuthRoute>
            <AuthRoute
                exact
                path="/admin/role"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <Admin />
            </AuthRoute>
            <AuthRoute
                exact
                path="/admin/aircraft"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <Admin />
            </AuthRoute>

            <AuthRoute
                exact
                path="/login"
                goToLogin={!isAuthWithLibrary}
                componentIsReloading={componentIsReloading}
                isAuthButNoUser={isAuthButNoUser}
                isAuthenticated={isFullyAuth}
                permissions={permissions}>
                <Login authService={authService} />
            </AuthRoute>
        </Switch>
    );

    const withSurrounding = (
        <>
            <Header authService={authService} />
            <div className="app-body">{routes}</div>
            <Footer />
        </>
    );

    const routesSetUp = isFullyAuth ? withSurrounding : routes;

    return (
        <AuthProvider authService={authService}>
            <QueryClientProvider client={queryClient}>
                <AppContext.Provider
                    value={{
                        messageBanner,
                        setMessageBanner: (message: IError) => setMessageBanner(message)
                    }}>
                    <Router>
                        {messageBanner.isBanner && <MessageBanner message={messageBanner.message} type={messageBanner.type} />}
                        <SessionDialog authService={authService} />
                        {routesSetUp}
                    </Router>
                </AppContext.Provider>
            </QueryClientProvider>
        </AuthProvider>
    );
};

export default App;
